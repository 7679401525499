<template>
  <div id="bg">
    <search-pic :type="type">
      <div style="display: flex">
        <div class="left-box">
          <div>
            <dv-border-box-12 style="padding: 10px ">
              <allinone
                id="wordcloud"
                :type="type.wordcloud"
                height="300px"
                url="big-screen/customer/elevator-quantity"
                name="客户电梯台量"></allinone>
            </dv-border-box-12>
          </div>
          <div>
            <dv-border-box-12 style="padding: 10px ">
              <allinone
                id="radarChart"
                :type="type.axisBar"
                height="300px"
                url="big-screen/month/repair-order/summary"
                name="每月工单汇总"></allinone>
            </dv-border-box-12>
          </div>
          <div>
            <dv-border-box-12 style="padding: 10px ">
              <allinone
                id="pie"
                :type="type.pie"
                height="300px"
                url="big-screen/district/project-quantity"
                name="各地区项目数"></allinone>
            </dv-border-box-12>
          </div>
        </div>
        <div class="center-box">
          <center></center>
          <centerceft2chart width="100%" height="560px"></centerceft2chart>
        </div>
        <div class="right-box">
          <div>
            <dv-border-box-12 style="padding: 10px ">
              <allinone
                :id="type.scatter"
                :type="type.scatter"
                height="300px"
                url="big-screen/brand-age/quantity"
                name="各品牌电梯的使用时间分布"></allinone>
            </dv-border-box-12>
          </div>
          <div>
            <dv-border-box-12 style="padding: 10px ">
              <allinone
                :id="type.bar"
                :type="type.bar"
                height="300px"
                url="big-screen/district/elevator-quantity"
                name="各地市电梯台量"></allinone>
            </dv-border-box-12>
          </div>
          <div>
            <dv-border-box-12 style="padding: 10px ">
              <allinone
                :id="type.sunburst"
                :type="type.pie"
                height="300px"
                url="big-screen/brand/elevator-quantity"
                name="各品牌台量占比"
              >
              </allinone>
            </dv-border-box-12>
          </div>
        </div>
      </div>
    </search-pic>
  </div>
</template>

<script>
  import SearchPic from "@/views/template/SearchPic";
  import allinone from "@/common/echart/allinone";
  import centerceft2chart from "@/components/echart/centerLeft/centerLeft2Chart";
  import center from "@/views/bigScreen/center";
  export default {
    components:{SearchPic,allinone,centerceft2chart,center},
    data() {
      return{
        filter: {
          name: "",
          regCode: "",
          factoryCode: "",
          rescueCode: "",
          type: "",
          status: "",
          projectName: "",
          deviceNo: "",
        },
        type: {
          axisBar: "axisBar",//折线柱状图
          defaultLine: "defaultLine",//默认的折线图
          radarchart: "RadarChart",//雷达图
          sankey: "sankey",//桑基图
          scatter: "scatter",//气泡图
          stackCharts: "stackCharts",
          sunburst: "sunburst",
          wordcloud: "wordcloud",
          ybar: "ybar",
          pie:"pie",
          bar:"bar",
        },
        option:{
          indicator:{},
        },
      };
    },
    mounted() {

    },
  };
</script>

<style scoped>
#bg{
  width: 100%;
  height: 100%;
  padding: 16px 16px 0 16px;
  background-image: url("../../assets/pageBg.png");
  background-size: cover;
  background-position: center center;
}
.left-box{
  width: 30%;
}
.center-box{
  width: 39%;
}
.right-box{
  width: 30%;
}
</style>